import * as React from "react";

import { Box, Link, Typography, makeStyles } from "@material-ui/core";
import { Link as GatsbyLink, graphql } from "gatsby";
import { TAGLINE, TITLE } from "../constants";

import { Content } from "../components/Content";
import { Header } from "../components/Header";
import { Helmet } from "react-helmet";
import { Hero } from "../components/Hero";
import { Theme } from "../components/Theme";

const useStyles = makeStyles({
  row: {
    width: "100%",
    display: "flex",
  },
  link: {
    color: "#FFF",
  },
  thumbnail: {
    width: "80px",
    borderRadius: "50%",
    background: "#000",
    padding: 12,
    marginRight: 24,
    height: "80px",
  },
  container: {},
});

const colors = ["#529CBF", "#8D72C3", "#E78D51"];

const IndexPage = (props) => {
  const { nodes: posts } = props.data.allMarkdownRemark;
  const classes = useStyles();

  return (
    <Theme>
      <main>
        <Helmet>
          <title>{TITLE}</title>
          <meta name="author" content={TITLE} />
          <meta name="description" content={TAGLINE} />
          <meta property="og:description" content={TAGLINE} />
          <meta property="og:title" content={TITLE} />
          <meta name="twitter:description" content={TAGLINE} />
          <meta name="twitter:title" content={TITLE} />
        </Helmet>
        <Header />
        <Hero />
        <Content>
          <Box className={classes.container}>
            {posts
              .filter((post) => post.frontmatter.published)
              .sort((a, b) => {
                const aDate = new Date(a.frontmatter.date);
                const bDate = new Date(b.frontmatter.date);

                return bDate.getTime() - aDate.getTime();
              })
              .map((post, i) => (
                <React.Fragment key={post.id}>
                  <Box className={classes.row}>
                    <Link
                      component={GatsbyLink}
                      to={post.frontmatter.path}
                      className={classes.link}
                      title={post.frontmatter.title}
                    >
                      <img
                        className={classes.thumbnail}
                        style={{
                          backgroundColor: colors[i % colors.length],
                        }}
                        src={`/images/logos/${post.frontmatter.image}.png`}
                        alt={post.frontmatter.title}
                      />
                    </Link>
                    <Box>
                      <Link
                        component={GatsbyLink}
                        to={post.frontmatter.path}
                        className={classes.link}
                        title={post.frontmatter.title}
                      >
                        <Typography variant="h5">
                          {post.frontmatter.title}
                        </Typography>
                      </Link>
                      <Typography variant="body2">
                        {post.frontmatter.date}
                      </Typography>
                      <Typography>{post.excerpt}</Typography>
                      <Link
                        component={GatsbyLink}
                        to={post.frontmatter.path}
                        className={classes.link}
                        title={post.frontmatter.title}
                      >
                        <Typography variant="body2">Read More</Typography>
                      </Link>
                    </Box>
                  </Box>
                  <Box
                    style={{
                      height: 50,
                    }}
                  />
                </React.Fragment>
              ))}
          </Box>
        </Content>
      </main>
    </Theme>
  );
};

export default IndexPage;

export const postQuery = graphql`
  query AllBlogPosts {
    allMarkdownRemark(sort: { order: DESC, fields: frontmatter___date }) {
      nodes {
        id
        excerpt(format: PLAIN, pruneLength: 1000)
        frontmatter {
          published
          title
          path
          date
          image
        }
      }
    }
  }
`;
